import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const PresseCentre = ({ image }) => (
  <div className="max-w-7xl mx-auto text-center">
    <StaticImage
      className="rounded-lg shadow-lg"
      src="../../images/presse/presse3.jpg"
      alt="Presse3"
    />
  </div>
);

export default PresseCentre;
